@font-face {
  font-family: 'Campton-Book';
  src:
    local('Campton-Book'),
    url('./fonts/Campton-Book/font.woff') format('woff');
}

@font-face {
  font-family: 'Campton-Light';
  src:
    local('Campton-Light'),
    url('./fonts/Campton-Light/font.woff') format('woff');
}

@font-face {
  font-family: 'Campton-Medium';
  src:
    local('Campton-Medium'),
    url('./fonts/Campton-Medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Campton-SemiBold-Heart';
  src:
    local('Campton-SemiBold-Heart'),
    url('./fonts/Campton-SemiBold-Heart/font.woff') format('woff');
}

/* POPPINS */
@font-face {
  font-family: 'Poppins-Regular';
  src:
    local('Poppins'),
    url('./fonts/Poppins/Poppins-Regular.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins-Medium';
  src:
    local('Poppins'),
    url('./fonts/Poppins/Poppins-Medium.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins-Bold';
  src:
    local('Poppins'),
    url('./fonts/Poppins/Poppins-Bold.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Avenir';
  src:
    local('Poppins'),
    url('./fonts/Avenir/Avenir-Book.woff') format('woff'),
    url('./fonts/Avenir/Avenir-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src:
    local('Gilroy-ExtraBold'),
    url('./fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
    url('./fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2');
}

#root {
  font-family: 'Campton-Medium', Verdana, sans-serif;
  background: #f4f4f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html,
body {
  margin: 0;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* disables error stack frames in order to view error boundary fallback */
/* iframe {
  display: none;
} */

img {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

.ant-modal-content {
  border-radius: 5px !important;
}
.ant-modal-header {
  border-radius: 5px 5px 0 0 !important;
}
.ant-modal-body {
  padding: 40px 20px !important;
}

.creator-form-item .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
  padding: 6px 12px;
}

.ant-tabs-nav::before {
  border: none !important;
}
.ant-tabs-nav-operations {
  font-size: 28px;
  font-weight: bold;
}

.ant-tabs-ink-bar {
  display: none;
}

.campaignContent {
  background-color: #edf6ff;
}

.ant-drawer-body {
  padding: 0 !important;
}

/**** ANTD BUTTONS ****/
button:focus {
  outline: none;
}
button.ant-btn {
  transition: 0.2s ease-in-out;
  text-shadow: none !important;
  box-shadow: none !important;
  font-size: 14px;
  border-width: 1px;
  border-radius: 20px;
}

/* PRIMARY BUTTON */
.ant-btn.ant-btn-primary {
  background: #23262f;
  color: #fff;
  border-color: #23262f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
button.ant-btn.ant-btn-primary:hover,
button.ant-btn.ant-btn-primary:focus {
  background: #027df0;
  color: #fff;
  border-color: #027df0;
}
button.ant-btn.ant-btn-primary:disabled {
  background: #999;
  color: #fff;
  border-color: #999;
  cursor: default;
}
button.ant-btn-dangerous.ant-btn-primary:hover,
button.ant-btn-dangerous.ant-btn-primary:focus {
  background: #f5222d;
  color: #fff;
  border-color: #f5222d;
}

/* SECONDARY & DEFAULT BUTTON */
button.ant-btn.ant-btn-secondary,
button.ant-btn.ant-btn-default {
  background: #fff;
  color: #23262f;
  border-color: #23262f;
}
button.ant-btn.ant-btn-secondary:hover,
button.ant-btn.ant-btn-secondary:focus,
button.ant-btn.ant-btn-default:hover,
button.ant-btn.ant-btn-default:focus {
  background: #fff;
  color: #027df0;
  border-color: #027df0;
}
button.ant-btn.ant-btn-secondary:disabled,
button.ant-btn.ant-btn-default:disabled {
  background: #fff;
  color: #999;
  border-color: #999;
  cursor: default;
}
button.ant-btn-dangerous.ant-btn-secondary:hover,
button.ant-btn-dangerous.ant-btn-secondary:focus {
  background: #fff;
  color: #f5222d;
  border-color: #f5222d;
}

/* LINK/TEXT BUTTON */
button.ant-btn.ant-btn-link,
button.ant-btn.ant-btn-text {
  padding-left: 4px;
  padding-right: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
button.ant-btn.ant-btn-link:hover,
button.ant-btn.ant-btn-link:focus,
button.ant-btn.ant-btn-text:hover,
button.ant-btn.ant-btn-text:focus {
  color: #23262f;
  border-color: transparent;
  background-color: transparent;
}
button.ant-btn.ant-btn-link:hover:disabled,
button.ant-btn.ant-btn-link:focus:disabled,
button.ant-btn.ant-btn-text:hover:disabled,
button.ant-btn.ant-btn-text:focus:disabled {
  color: rgba(0, 0, 0, 0.25);
}

label,
input,
.ant-menu,
.ant-select-dropdown,
.ant-dropdown-menu,
.ant-modal,
.ant-select,
.ant-picker-dropdown,
.ant-message-custom-content,
.ant-select-item,
.ant-input,
.ant-select-item-option,
.ant-select-selection-search-mirror,
.ant-select-multiple .ant-select-selector .ant-select-selection-search-input,
.ant-drawer-extra,
.ant-drawer-body,
.ant-drawer-title,
.ant-popover-content,
.ant-notification {
  font-family: 'Campton-Medium', Verdana, sans-serif;
}
/* fixes password icon overlap with 1password */
.ant-input-password {
  padding-right: 30px !important;
}

.ant-tooltip-inner {
  border-radius: 5px !important;
}
.tooltip-close-button {
  cursor: pointer;
}

.ant-popover-title {
  font-size: 1rem;
}

/* fixes issue with phone code dropdown being too wide on mobile (signup) */
.ant-select-dropdown {
  max-width: 250px;
}

.ant-drawer-extra {
  color: #080830;
  flex: 1 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-drawer-mask {
  backdrop-filter: blur(1px);
}

.ant-empty-description {
  color: #999;
}

.ant-notification-notice {
  border-radius: 10px !important;
}
.ant-notification-notice-description {
  color: #777e90;
}
